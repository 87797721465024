const middleStandard = 10;
const endStandard = 20;

export const getFastDateFromNow = () => {
    const currentDate = new Date().getDate();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    let year, month, due;
    if (currentDate > endStandard) {
        /**
         * 지금 월 말
         * 다음 달 초를 가장 빠른 시작일로 설정
         */
        due = 0;

        if (currentMonth + 1 > 12) {
            // 지금 12월이면 내년 1월로
            month = 1;
            year = currentYear + 1;

            return [year, month, due];
        }
        month = currentMonth + 1;
        year = currentYear;

        return [year, month, due];
    } else if (currentDate > middleStandard) {
        return [currentYear, currentMonth, 2];
    }

    return [currentYear, currentMonth, 1];
};

export const getFastDateFromStart = () => {
    const [startYear, startMonth] = getFastDateFromNow();

    let year = startYear,
        month = startMonth,
        due = 0;

    month += 1;

    if (month > 12) {
        year += 1;
        month = 1;

        return [year, month, due];
    }

    return [year, month, due];
};

const compareDate = (date1, date2) => {
    // Date1이 더 빠르면 -1
    // Date2가 더 빠르면 1
    // 같으면 0

    const { year: year1, month: month1 } = date1;
    const { year: year2, month: month2 } = date2;

    if (year2 < year1) return 1;
    else if (year1 < year2) return -1;
    else {
        if (month2 < month1) return 1;
        else if (month1 < month2) return -1;
        else {
            // 다 같음
            return 0;
        }
    }
};

const updateDate = (current, name, upDown, type, pair) => {
    const copied = { ...current };
    copied[name] += upDown;

    if (copied.due > 2) {
        copied.due = 0;
    }

    if (copied.month > 12) {
        copied.month = 1;
        copied.year += 1;
    }

    if (copied.due < 0) {
        copied.due = 2;
    }

    if (copied.month < 1) {
        copied.month = 12;
        copied.year -= 1;
    }

    let currentYear, currentMonth, currentDue;

    if (type === "startDue")
        [currentYear, currentMonth, currentDue] = getFastDateFromNow();
    else if (type === "endDue")
        [currentYear, currentMonth, currentDue] = getFastDateFromStart();

    if (copied.year < currentYear) {
        copied.year = currentYear;
    }

    if (copied.year === currentYear && copied.month < currentMonth) {
        copied.month = currentMonth;
    }

    if (
        copied.year === currentYear &&
        copied.month === currentMonth &&
        copied.due < currentDue
    ) {
        copied.due = currentDue;
    }

    return copied;
};

export const updatePairDate = (pair, current, type) => {
    const compare = compareDate(current, pair);

    if (compare === 0) {
        if (type === "startDue") {
            return updateDate(current, "month", 1, "endDue", pair);
        } else if (type === "endDue") {
            return updateDate(current, "month", -1, "startDue", pair);
        }
    }

    if (type === "startDue" && compare === -1) {
        return updateDate(current, "year", 1, "endDue", pair);
    }

    if (type === "endDue" && compare === 1) {
        return updateDate(current, "year", -1, "startDue", pair);
    }

    return current;
};

export const getUpdatedDate = (name, type, upDown, current, pairCurrent) => {
    let {
        year: updatedYear,
        month: updatedMonth,
        due: updatedDue,
    } = updateDate(current, name, upDown, type, pairCurrent);

    let {
        year: pairYear,
        month: pairMonth,
        due: pairDue,
    } = updatePairDate(
        { year: updatedYear, month: updatedMonth, due: updatedDue },
        pairCurrent,
        type
    );

    const compare = compareDate(
        { year: updatedYear, month: updatedMonth },
        { year: pairYear, month: pairMonth }
    );

    let isWrong = false;

    if (type === "startDue") {
        if (compare === 0 || compare === 1) {
            isWrong = true;
        }
    } else if (type === "endDue") {
        if (compare === 0 || compare === -1) {
            isWrong = true;
        }
    }
    if (isWrong) {
        updatedYear = current.year;
        updatedMonth = current.month;
        updatedDue = current.due;

        pairYear = pairCurrent.year;
        pairMonth = pairCurrent.month;
        pairDue = pairCurrent.due;
    }

    return [
        updatedYear,
        updatedMonth,
        updatedDue,
        pairYear,
        pairMonth,
        pairDue,
    ];
};
